<script lang="ts" setup>
import Banner from "@/Components/Banner.vue";
import { computed } from "vue";

const props = defineProps<{
    errors?: { [key: string]: string };
}>();

const messages = computed(() => Object.values(props.errors ?? {}));
</script>

<template>
    <Banner v-if="messages.length" title="Error" type="error">
        <ul>
            <li v-for="message in messages">{{ message }}</li>
        </ul>
    </Banner>
</template>
